/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';

import { FaPhone, FaVideo, FaHeadset } from 'react-icons/fa';
import {
  BoxComponent,
  Container,
  Title,
  Paragrafo,
  Inline,
  ButtonWhite,
} from './styles';
import Menu from '../../components/Menu';
import logo from '../../imgs/logo-white.png';
import ApiErp from '../../services/ApiErp';
import Loader from '../../components/Loader';
import checkTokenByStatusHTTP from '../../services/checkTokenByStatusHTTP';

export default class Saude24h extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titulo: 'Atendimento 24h',
      paragrafo:
        'O Saúde 24h é um serviço que oferece informações e esclarecimentos em saúde a qualquer hora do dia ou da noite. Enfermeiras experientes e capacitadas estão à disposição para responder as mais diversas dúvidas sobre saúde.',
      telefone: '0800 888 8282',
      loading: false,
    };
  }

  videoCall = async () => {
    await this.setState({ loading: true });

    const token = await sessionStorage.getItem('token');
    await ApiErp.setHeader('Authorization', `Bearer ${token}`);

    const response = await ApiErp.post('area-cliente/video-chamada', {});

    checkTokenByStatusHTTP().valid(response.status);

    if (response.ok) {
      await this.redirectVideoChamada(response.data.output.link);
    }

    await this.setState({ loading: false });
  };

  redirectVideoChamada = async url => {
    window.location.replace(url);
  };

  buscarApolices = async idSegurado => {
    const response = await ApiErp.get(`segurado/${idSegurado}/apolices`);

    checkTokenByStatusHTTP().valid(response.status);

    if (response.ok) {
      const { ok, output } = response.data;
      if (ok) {
        return output;
      }
    }
    return [];
  };

  render() {
    const { titulo, paragrafo, telefone, loading } = this.state;
    return (
      <Container>
        <Loader active={loading} />
        <img src={logo} alt="Logo do Viver" />
        <Menu saude24h />
        <BoxComponent>
          <Title>
            <FaHeadset /> {titulo}
          </Title>

          <Paragrafo>{paragrafo}</Paragrafo>

          <Inline>
            <ButtonWhite href={`tel:${telefone}`} target="_blank">
              <FaPhone /> Ligue Agora
            </ButtonWhite>

            <ButtonWhite onClick={() => this.videoCall()}>
              <FaVideo /> Video Chamada
            </ButtonWhite>
          </Inline>
        </BoxComponent>
      </Container>
    );
  }
}
