import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import CartaoViver from './pages/CartaoViver';
import PossoAjudar from './pages/PossoAjudar';
import MeuPlano from './pages/MeuPlano';
import Faturas from './pages/Faturas';
import Saude24h from './pages/Saude24h';
import BemVindoAoViver from './pages/BemVindoAoViver';

export default function Routes() {
  return (
    <BrowserRouter>
      {window.location.pathname === '/bem-vindo-ao-viver' ? (
        <Redirect to="/bem-vindo-ao-viver" component={BemVindoAoViver} />
      ) : (
        !sessionStorage.getItem('idSegurado') && <Redirect to="/login" />
      )}

      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" exact component={Home} />
        <Route path="/cartao-viver" component={CartaoViver} />
        <Route path="/posso-ajudar" component={PossoAjudar} />
        <Route path="/meu-plano" component={MeuPlano} />
        <Route path="/faturas" component={Faturas} />
        <Route path="/saude-24h" component={Saude24h} />
        <Route path="/bem-vindo-ao-viver" component={BemVindoAoViver} />
      </Switch>
    </BrowserRouter>
  );
}
