import styled from 'styled-components';

export const FormComponent = styled.form`
  input {
    width: 100%;
    padding: 20px 30px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
    font-size: 13px;
  }
  input:focus {
    border: 1px solid #0a7a73;
    transition: 0.5s ease-in;
    -webkit-transition: 0.5s ease-in;
  }
`;
export const SubmitButton = styled.button.attrs({ type: 'submit' })`
  width: 100%;
  padding: 20px 15px;
  margin-top: 10px;
  border-radius: 5px;
  background: #0a7a73;
  color: #fff;
  font-size: 17px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #0f9b92;
  }

  &:disabled {
    background: #ddd;
    cursor: not-allowed;
  }

  svg {
    margin-right: 3px;
  }
`;

export const BoxComponent = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  width: 100%;
  margin: 150px auto;
  padding: 50px;
  text-align: center;
  h1 {
    text-align: center;
  }
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 900px) {
    width: 90%;
    left: 5%;
    margin-top: 50px;
  }
`;

export const LojasImg = styled.div`
  margin-top: 30px;
  img {
    width: 50%;
    padding: 5px;
  }
  @media (max-width: 900px) {
    img {
      width: 100%;
    }
  }
`;
export const AvisoPoliticas = styled.p`
  margin-top: 10px;
  color: #aaa;
  font-size: 13px;
  a {
    color: #538a88;
    text-decoration: none;
  }
`;
