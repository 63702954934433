/* eslint-disable radix */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { FaPhone, FaMapMarkerAlt, FaHospital } from 'react-icons/fa';
import { CardComponent, BotaoLink, DivCenter } from './styles';

export default class RedeCredenciada extends Component {
  processaLinkEndereco = () => {
    const { logradouro, numeroEndereco, bairro, cidade, uf } = this.props;
    let enderecoPreProcessado = `${logradouro}+${numeroEndereco}+${bairro}+${cidade}+${uf}`;
    enderecoPreProcessado = enderecoPreProcessado.split(' ');
    const enderecoProcessado = enderecoPreProcessado.join('+');

    return `https://www.google.com.br/maps/place/${enderecoProcessado}`;
  };

  processaValor = () => {
    const { tabelaDiferenciada, precoViver } = this.props;
    return parseInt(tabelaDiferenciada)
      ? 'Tabela Diferenciada'
      : `R$ ${precoViver}`;
  };

  render() {
    const {
      nomeCredenciado,
      logradouro,
      numeroEndereco,
      bairro,
      cidade,
      uf,
      especialidade,
      telefone,
    } = this.props;

    const enderecoProcessado = this.processaLinkEndereco();
    const valor = this.processaValor();

    return (
      <CardComponent>
        <h1>
          <FaHospital /> {nomeCredenciado}
        </h1>
        <h4>
          {logradouro} nº {numeroEndereco}, {bairro}, {cidade}/{uf}
        </h4>
        <h4>
          <b>Telefone:</b> {telefone}
        </h4>
        <h3>{especialidade}</h3>
        <p>{valor}</p>

        <DivCenter>
          <BotaoLink active href={`tel:${telefone}`}>
            <FaPhone /> Ligar
          </BotaoLink>
          <BotaoLink href={enderecoProcessado}>
            <FaMapMarkerAlt /> Ver no Mapa
          </BotaoLink>
        </DivCenter>
      </CardComponent>
    );
  }
}
