/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { BoxComponent, Container, Title } from './styles';
import Menu from '../../components/Menu';
import logo from '../../imgs/logo-white.png';
import ApiErp from '../../services/ApiErp';
import ApoliceView from '../../views/cards/Apolice';
import Loader from '../../components/Loader';
import checkTokenByStatusHTTP from '../../services/checkTokenByStatusHTTP';

export default class MeuPlano extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apolices: [],
      dependentes: [],
      infosSegurado: [],
      nivelEstipulante: 0,
      loading: true,
    };
    this.main();
  }

  main = async () => {
    const idSegurado = await sessionStorage.getItem('idSegurado');
    const nivelEstipulante = await sessionStorage.getItem('nivelEstipulante');
    const token = await sessionStorage.getItem('token');
    await ApiErp.setHeader('Authorization', `Bearer ${token}`);

    const infosSegurado = await this.buscarInfosSegurado(idSegurado);
    const dependentes = await this.buscarDependentes(idSegurado);
    const apolices = await this.buscarApolices(idSegurado);

    await this.setState({
      nivelEstipulante,
      apolices,
      dependentes,
      infosSegurado,
      loading: false,
    });
  };

  buscarApolices = async idSegurado => {
    const response = await ApiErp.get(`segurado/${idSegurado}/apolices`);

    checkTokenByStatusHTTP().valid(response.status);

    if (response.ok) {
      const { ok, output } = response.data;
      if (ok) {
        return output;
      }
    }
    return [];
  };

  buscarDependentes = async idSegurado => {
    const response = await ApiErp.get(`segurado/dependentes/${idSegurado}`);

    checkTokenByStatusHTTP().valid(response.status);

    if (response.ok) {
      const { ok, output } = response.data;
      if (ok) {
        return output;
      }
    }
    return [];
  };

  buscarInfosSegurado = async idSegurado => {
    const response = await ApiErp.get(`segurado/${idSegurado}`);

    checkTokenByStatusHTTP().valid(response.status);

    if (response.ok) {
      const { ok, output } = response.data;
      if (ok) {
        return output;
      }
    }
    return [];
  };

  render() {
    const {
      dependentes,
      infosSegurado,
      nivelEstipulante,
      apolices,
      loading,
    } = this.state;
    return (
      <Container>
        <Loader active={loading} />
        <img src={logo} alt="Logo do Viver" />
        <Menu plano />
        <BoxComponent>
          <Title>
            <FaFileInvoiceDollar /> Minhas apólices
          </Title>
          {apolices.map((apolice, index) => {
            if (
              apolice.status === 'Em Vigência' ||
              apolice.status === 'Envelope em Produção' ||
              apolice.status === 'Pedido de Cartão' ||
              apolice.status === 'Cartão Enviado'
            ) {
              return (
                <ApoliceView
                  key={index.toString()}
                  apolice={apolice}
                  dependentes={dependentes}
                  infosSegurado={infosSegurado}
                  nivelEstipulante={nivelEstipulante}
                />
              );
            }
            return null;
          })}
        </BoxComponent>
      </Container>
    );
  }
}
