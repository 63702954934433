import React from 'react';
import { ImageComponent, BoxComponent } from './styles';
import ActivationArea from '../../views/cards/BemVindoAoViver';

import logoWhite from '../../imgs/logo-white.png';

export default function BemVindoAoViver() {
  return (
    <>
      <ImageComponent>
        <img src={logoWhite} alt="Planos Viver" />
      </ImageComponent>

      <BoxComponent>
        <h1>Para desbloquear seu cartão informe: </h1>
        <h4>Dados do Titular</h4>
        <ActivationArea />
      </BoxComponent>
    </>
  );
}
