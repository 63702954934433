import styled from 'styled-components';

export const Cartao = styled.div`
  border-radius: 20px;
  background: #107e76;
  height: 220px;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
  max-width: 350px;
  @media (min-width: 990px) {
    margin: 10px;
    max-width: 330px;
  }
`;
export const FaixaCartao = styled.div`
  height: 50px;
  background: #a6e1dd;
  padding: 10px;
  padding-left: 20px;
  text-align: left;
`;
export const NomesCartao = styled.div`
  text-align: left;
  padding: 10px;
  height: 100px;
  padding-left: 20px;
`;
export const CpfCliente = styled.p`
  font-size: 20px;
  color: #000;
`;
export const NomeCliente = styled.p`
  font-size: 15px;
  color: #fff;
`;
export const NomeResponsavel = styled.p`
  font-size: 13px;
  color: #fff;
`;
export const ContainerLogo = styled.div`
  padding: 10px;
  height: 115px;
  padding-left: 20px;
  text-align: left;
`;
export const Logo = styled.img`
  width: auto !important;
  height: 60px !important;
`;
export const TagCartao = styled.p`
  font-size: 13px;
  color: #fff;
  text-align: right;
`;
