import { createGlobalStyle } from 'styled-components';
import Background from '../imgs/bg.png';

export default createGlobalStyle`

  * {
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
  }
  html, body, #root{
    min-height:100%;
  }
  body {
    -webkit-font-smoothing: antialiased !important;
    background:#0a7a73;
    background-image: url(${Background});
    background-size:100% auto;
    background-position:center;
    font-family: 'Signika', sans-serif;
    background-attachment: fixed;
  }
  h1,h2,h3,h4 {
    color: #0a7a73;
  }
  @media (max-width: 768px) {
    body{
      background-size:auto 100%;
    }
  }
`;
