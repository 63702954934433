import React from 'react';
import { FaHome } from 'react-icons/fa';
import { BoxComponent, Container, Title } from './styles';
import Menu from '../../components/Menu';
import logo from '../../imgs/logo-white.png';
import RedeCredenciada from '../../views/forms/RedeCredenciada';

export default function Home() {
  return (
    <Container>
      <img src={logo} alt="Logo do Viver" />
      <Menu home />
      <BoxComponent>
        <Title>
          <FaHome /> Rede Credenciada
        </Title>
        <RedeCredenciada />
      </BoxComponent>
    </Container>
  );
}
