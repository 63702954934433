/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { BoxComponent, Container, Title } from './styles';
import Menu from '../../components/Menu';
import logo from '../../imgs/logo-white.png';
import ApiErp from '../../services/ApiErp';
import FaturaCard from '../../views/cards/Fatura';
import Loader from '../../components/Loader';
import checkTokenByStatusHTTP from '../../services/checkTokenByStatusHTTP';

export default class Faturas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faturas: [],
      loading: true,
    };
    this.main();
  }

  main = async () => {
    const idSegurado = await sessionStorage.getItem('idSegurado');
    const token = await sessionStorage.getItem('token');
    await ApiErp.setHeader('Authorization', `Bearer ${token}`);

    const faturas = await this.buscarFaturas(idSegurado);

    await this.setState({
      loading: false,
      faturas,
    });
  };

  buscarFaturas = async idSegurado => {
    const response = await ApiErp.get(`faturas/segurado/${idSegurado}`);

    checkTokenByStatusHTTP().valid(response.status);

    if (response.ok) {
      const { ok, output } = response.data;
      if (ok) {
        return output;
      }
    }
    return [];
  };

  render() {
    const { faturas, loading } = this.state;
    return (
      <Container>
        <Loader active={loading} />
        <img src={logo} alt="Logo do Viver" />
        <Menu plano />
        <BoxComponent>
          <Title>
            <FaFileInvoiceDollar /> Faturas Pendentes
          </Title>
          {faturas.map(fatura => {
            if (fatura.status !== 'Paga') {
              return <FaturaCard fatura={fatura} />;
            }
            return null;
          })}
        </BoxComponent>
      </Container>
    );
  }
}
