import styled from 'styled-components';

export const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  margin: 15px;
  text-align: left;
`;
export const TitleCard = styled.h1`
  line-height: 30px;
  font-size: 20px;
  color: #085e58;
  font-weight: bold;
`;
export const SubtitleCard = styled.h2`
  line-height: 25px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
`;
export const DescriptionCard = styled.p`
  line-height: 25px;
  font-size: 19px;
  color: #666;
`;
export const ContainerBotoesCard = styled.div`
  @media (max-width: 900px) {
    padding-bottom: 20px;
  }
`;
export const BotaoCardVerde = styled.a`
  border-radius: 10px;
  background-color: #0a726b;
  border-color: #085e58;
  border-width: 1px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  transition: all 0.4s ease;
  float: right;

  &:hover {
    background: #085e58;
  }

  @media (max-width: 900px) {
    float: none;
    width: 100%;
    display: block;
  }
`;
export const Inline = styled.div`
  display: inline-flex;
  div {
    padding: 15px;
  }
  @media (max-width: 900px) {
    display: block;
    div {
      padding: 5px;
    }
  }
`;
