import React, { useState } from 'react';
import { AiOutlineUnlock } from 'react-icons/ai';
import InputMask from 'react-input-mask';
import { FormComponent, SubmitButton, LabelForm } from './styles';
import Loader from '../../../components/Loader';
import MsgSuccess from '../../../components/MsgSuccess';
import MsgError from '../../../components/MsgError';
import DatePicker from '../../../components/DatePicker';
import ApiErp from '../../../services/ApiErp';

export default function BemVindoAoViver(props) {
  const [ok, setOk] = useState('');
  const [message, setMessage] = useState('');

  // inputs
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [birth, setBirth] = useState('');

  const alertError = message => <MsgError msgDesc={message} setOk={setOk} />;

  const alertSuccess = message => (
    <MsgSuccess
      msgTitle={message}
      msgDescLink="Clicando Aqui"
      msgLink="/login"
    />
  );

  const handleOnChange = event => {
    const { name, value } = event.target;
    setBirth({ [name]: value });
  }

  // envio para a API
  const handleSubmit = async (e, email, cpf, birth) => {
    e.preventDefault();
    setLoading(true);
    
    const resultRequest = await ApiErp.post('area-cliente/ativacao-cartao', {
      email,
      cpf,
      nascimento: birth.birth,
    });

    if (resultRequest.ok) {
      const { ok, message } = resultRequest.data;

      setLoading(false);
      setOk(ok);

      if (ok) {
        setMessage(message);
      } else {
        setMessage(message);
      }
    }
  };

  return (
    <>
      <Loader active={loading} />

      {ok === true && alertSuccess(message)}
      {ok === false && alertError(message)}

      <FormComponent onSubmit={e => handleSubmit(e, email, cpf, birth)}>
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
          placeholder="E-mail"
        />
        <InputMask
          mask="999.999.999-99"
          type="text"
          value={cpf}
          onChange={e => setCpf(e.target.value)}
          required
          placeholder="CPF"
        />
        <div>
          <LabelForm>Data de Nascimento</LabelForm>
        </div>
        <div className="select-area">
          <DatePicker inputName="birth" changeMethod={handleOnChange}/>
          {/* <input
            type="date"
            value={birth}
            required
            onChange={e => setBirth(e.target.value)}
          /> */}
        </div>
        <div className="terms-area">
          <input
            className="input-checkbox"
            type="checkbox"
            required
            id="termsRead"
            name="termsRead"
          />
          <label htmlFor="termsRead">
            Li e aceito os termos das{' '}
            <a
              href="http://planoviver.com.br/politicas-privacidade"
              rel="noopener noreferrer"
              target="_blank"
            >
              políticas de privacidade
            </a>
          </label>
        </div>

        <SubmitButton>
          <AiOutlineUnlock size={30} />
          <span className="label-btn">Desbloquear</span>
        </SubmitButton>
      </FormComponent>
    </>
  );
}
