import styled from 'styled-components';

export const MenuBar = styled.div`
  width: 100%;
  background: #fff;
  max-width: 800px;
  margin: auto;
  z-index: 1;
  position: relative;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  a {
    padding: 20px 30px;
    border: 2px solid #fff;
    border-radius: 20px;
    display: inline-block;
    width: calc(100% / 6);
    text-align: center;
    justify-content: center;
    font-size: 13px;
    text-decoration: none;
    color: #0a7a73;
    transition: 0.2s ease-in;
    -webkit-transition: 0.2s ease-in;
  }
  .active {
    color: #fff;
    background: #0a7a73;
  }
  a:hover {
    background: #ffc521;
    color: #fff;
  }
  @media (max-width: 768px) {
    a {
      font-size: 9px;
      padding: 15px 5px;
    }
  }
`;
export const IconMenu = styled.div`
  font-size: 20px;
  word-wrap: break-word;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
export const BgLoader = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
export const MsgSuccessCard = styled.div`
  background: #fff;
  padding: 20px;
  width: 400px;
  margin: 70px auto;
  border-radius: 30px;
  text-align: center;

  svg {
    margin-left: -30px;
  }
  button {
    width: 100%;
    padding: 18px 15px;
    margin-top: 20px;
    border-radius: 30px;
    border: 1px solid #1e5d55;
    background-color: #fff;
    color: #1e5d55;
    font-size: 17px;
    cursor: pointer;
    transition: 0.5s;
  }
  button:hover {
    border: 1px solid #fff;
    background-color: #1e5d55;
    color: #fff;
  }
  @media (max-width: 430px) {
    width: 70%;
    left: 5%;
    margin-top: 50px;
  }
`;
export const MsgErrorCard = styled.div`
  background: #fff;
  padding: 20px;
  width: 400px;
  margin: 70px auto;
  border-radius: 30px;
  text-align: center;

  svg {
    margin-left: -30px;
  }
  button {
    width: 100%;
    padding: 18px 15px;
    margin-top: 20px;
    border-radius: 30px;
    border: 1px solid rgb(205, 80, 80);
    background-color: #fff;
    color: rgb(205, 80, 80);
    font-size: 17px;
    cursor: pointer;
    transition: 0.5s;
  }
  button:hover {
    border: 1px solid #fff;
    background-color: rgb(205, 80, 80);
    color: #fff;
  }
  @media (max-width: 430px) {
    width: 70%;
    left: 5%;
    margin-top: 50px;
  }
`;
export const LoaderCard = styled.div`
  background: #fff;
  padding: 20px;
  width: 200px;
  margin: auto;
  top: 200px;
  position: relative;
  border-radius: 30px;
  display: inline-block;

  svg {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    display: block;
    width: auto;
    height: 60px;
    display: block;
    margin: 20px auto;
    color: #0a7a73;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
export const TextLoader = styled.p`
  color: #0a7a73;
`;
