import styled from 'styled-components';

export const CardComponent = styled.div`
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-bottom: 30px;
  margin-top: 30px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 30px 50px;
  background: #fff;
  text-align: left;

  h1 {
    font-size: 35px;
    font-weight: 800;
    color: #0a7a73;
    line-height: 35px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 17px;
    font-weight: 400;
    color: #333 !important;
    line-height: 25px;
  }
  h3 {
    font-size: 20px;
    font-weight: 800;
    color: #0b5e58;
    line-height: 20px;
  }
  p {
    font-size: 22px;
  }
  @media (max-width: 900px) {
    padding: 30px;

    h1 {
      font-size: 23px;
      line-height: 25px;
    }
  }
`;

export const DivCenter = styled.div`
  text-align: center;
  margin-top: 25px;
`;
export const BotaoLink = styled.a`
  text-decoration: none;
  display: inline-block;
  background: transparent;

  color: ${props => (props.active ? '#fff' : '#444')};
  background: ${props => (props.active ? '#0a7a73' : '#fff')};
  border: 2px solid ${props => (props.active ? '#0a7a73' : '#444')};

  font-family: 'Signika';
  font-weight: 300;
  padding: 5px 20px;
  margin: 5px;
  transition: all 0.4s ease;
  border-radius: 30px;
  font-size: 18px;
  transition: all 0.4s ease;
  justify-content: center;

  &:hover {
    background: #ffc521;
    border-color: #ffc521;
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
