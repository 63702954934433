
function checkTokenByStatusHTTP() {

  const valid = (status) => {
    if (status === 401) {
      sessionStorage.removeItem('idSegurado');
      sessionStorage.removeItem('idApolice');
      sessionStorage.removeItem('nivelEstipulante');

      alert("Sua sessão expirou. Por favor, efetue o login novamente.");
      window.location = '/login';
    }
  }

  return {valid}

}


export default checkTokenByStatusHTTP;
