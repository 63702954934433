/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';

import { FaSearch } from 'react-icons/fa';
import { SelectComponent, BotaoCategoria, BotaoSubmit } from './styles';
import ApiCredenciados from '../../../services/ApiCredenciados';
import RedeCredenciadaCard from '../../cards/RedeCredenciada';
import Loader from '../../../components/Loader';

export default class RedeCredenciada extends Component {
  constructor(props) {
    super(props);

    this.state = {
      botoesCategoria: [true, false, false, false],
      categoriaID: [14, 16, 18, 19],
      categoriaDesc: ['Consultas', 'Exames', 'Bem Estar', 'Clube de Vantagens'],
      especialidadesSelector: [
        {
          nome: 'Carregando ...',
          id: 0,
        },
      ],
      loading: false,
      especialidadeSelecionada: '',
      servicos: [],
    };
    this.buscarEspecialidades(this.state.categoriaID[0]);
  }

  selecionarCategoria = async index => {
    const { categoriaID } = this.state;
    const botoesCategoria = [false, false, false, false];
    botoesCategoria[index] = true;
    this.setState({ botoesCategoria });

    this.buscarEspecialidades(categoriaID[index]);
  };

  buscarEspecialidades = async idCategoria => {
    const resultRequest = await ApiCredenciados.get(
      `especialidades/${idCategoria}/app`
    );
    if (resultRequest.ok) {
      const { ok, output } = resultRequest.data;
      if (ok) {
        await this.setState({
          especialidadesSelector: output,
          especialidadeSelecionada: output[0].id,
        });
      }
    }
  };

  pesquisarServicosPorEspecialidade = async () => {
    const idSegurado = await sessionStorage.getItem('idSegurado');
    const { especialidadeSelecionada } = this.state;

    this.setState({ loading: true });

    const resultRequest = await ApiCredenciados.get(
      `servico/especialidade/${especialidadeSelecionada}/${idSegurado}`
    );
    if (resultRequest.ok) {
      const { ok, output } = resultRequest.data;
      if (ok) {
        await this.setState({ servicos: output, loading: false });
      } else {
        await this.setState({ servicos: [], loading: false });
      }
    }
  };

  render() {
    const {
      botoesCategoria,
      especialidadesSelector,
      categoriaDesc,
      loading,
    } = this.state;

    return (
      <>
        <Loader active={loading} />

        {botoesCategoria.map((botaoCategoria, index) => (
          <BotaoCategoria
            active={botaoCategoria}
            key={index.toString()}
            onClick={() => this.selecionarCategoria(index)}
          >
            {categoriaDesc[index]}
          </BotaoCategoria>
        ))}

        <SelectComponent
          onChange={e =>
            this.setState({ especialidadeSelecionada: e.target.value })
          }
        >
          {especialidadesSelector.map(especialidade => (
            <option value={especialidade.id} key={especialidade.id}>
              {especialidade.nome}
            </option>
          ))}
        </SelectComponent>

        <BotaoSubmit onClick={this.pesquisarServicosPorEspecialidade}>
          <FaSearch /> Procurar
        </BotaoSubmit>

        {/* Cards com o Resultado */}

        {this.state.servicos.map((servico, index) => {
          if (servico.ativo) {
            return (
              <RedeCredenciadaCard
                key={index.toString()}
                nomeCredenciado={servico.nome_credenciado}
                logradouro={servico.logradouro}
                numeroEndereco={servico.numero_endereco}
                bairro={servico.bairro}
                cidade={servico.cidade}
                uf={servico.uf}
                especialidade={servico.especialidade}
                telefone={servico.telefone}
                tabelaDiferenciada={servico.tabela_diferenciada}
                precoViver={servico.preco_viver}
              />
            );
          }
          return null;
        })}
      </>
    );
  }
}
