import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  img {
    margin-bottom: 20px;
  }
`;
export const Title = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;
export const Paragrafo = styled.p`
  font-size: 17px;
  letter-spacing: 1px;
  margin-bottom: 20px;
`;
export const Inline = styled.div`
  display: inline-flex;
  @media (max-width: 900px) {
    display: block;
  }
`;
export const ButtonWhite = styled.a`
  border: 2px solid #0a7a73;
  color: #0a7a73;
  text-decoration: none;
  border-radius: 20px;
  padding: 20px 40px;
  margin: 10px;
  transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  display: block;
  cursor: pointer;
  &:hover {
    background: #0a7a73;
    color: #fff;
  }
`;
export const BoxComponent = styled.div`
  background: #fff;
  border-radius: 20px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 50px;
  text-align: center;
  img {
    max-width: 250px;
    width: 100%;
  }
`;
