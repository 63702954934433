import React, { useState } from 'react';

function DatePicker(props) {

  const {inputName, changeMethod} = props;

  const [ valuePicker, setValuePicker ] = useState({
    day:1,
    month:1,
    year:new Date().getFullYear(),
  });

  const changeDate = event => {
    
    const { name, value } = event.target;
    const arrayValuePicker = {...valuePicker, [name]:value}
    setValuePicker(arrayValuePicker)

    const e = {
      target:{
        name: inputName,
        value: arrayValuePicker.year + "-" + arrayValuePicker.month + "-" + arrayValuePicker.day
      }
    }
    changeMethod(e)

  }

  const dayOnMonth = (month, year) => {
    var date = new Date(year, month, 0);
    return date.getDate();
  }

  const generateOptions = (min, max, month) => {

    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ];

    let options = [];
    for (let index = min; index <= max; index++) {
      if (month) {
        options.push(<option key={index} value={index}>{months[index-1]}</option>);
      } else {
        options.push(<option key={index}>{index}</option>);
      }
    }
    return options
  }

  return (
    <>
      
      <select type="select" name="day" className="day" value={valuePicker.day} onChange={e => changeDate(e) }>
        {generateOptions(1, dayOnMonth(valuePicker.month, valuePicker.year, false))}
      </select>

      <select type="select" name="month" className="month" value={valuePicker.month} onChange={e => changeDate(e) }>
        {generateOptions(1, 12, true)}
      </select>

      <select type="select" name="year" className="year" value={valuePicker.year} onChange={e => changeDate(e) }>
        {generateOptions(1940, new Date().getFullYear(), false)}
      </select>

    </>
  )
}

export default DatePicker;
