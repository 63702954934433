import React, { Component } from 'react';
import { FaSignInAlt } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { Redirect } from 'react-router-dom';

import {
  FormComponent,
  BoxComponent,
  SubmitButton,
  LojasImg,
  AvisoPoliticas,
} from './styles';
import logo from '../../../imgs/logo.png';
import googlePlay from '../../../imgs/disponivel-google-play.png';
import appStore from '../../../imgs/disponivel-app-store.png';
import ApiErp from '../../../services/ApiErp';
import Loader from '../../../components/Loader';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      senha: '',
      loading: false,
      login: !!sessionStorage.getItem('idSegurado'),
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });

    const { email, senha } = this.state;
    const resultRequest = await ApiErp.post('area-cliente/login', {
      email,
      senha,
    });
    if (resultRequest.ok) {
      const { ok, output, message } = resultRequest.data;

      if (ok) {
        sessionStorage.setItem('idSegurado', output.idSegurado);
        sessionStorage.setItem('idApolice', output.idApolice);
        sessionStorage.setItem('nivelEstipulante', output.nivelEstipulante);
        sessionStorage.setItem('token', output.token);

        this.setState({ loading: false, login: true });
      } else {
        alert('Ops', message);
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const { email, senha, loading, login } = this.state;
    return (
      <BoxComponent>
        <Loader active={loading} />
        {login ? <Redirect to="/" /> : ''}

        <img src={logo} alt="Logo do Viver" />
        <FormComponent onSubmit={this.handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
            required
            placeholder="E-mail"
          />
          <input
            type="password"
            value={senha}
            onChange={e => this.setState({ senha: e.target.value })}
            required
            placeholder="Senha"
          />
          <SubmitButton disabled={loading}>
            <FaSignInAlt />
            {loading ? 'Carregando...' : 'Entrar'}
          </SubmitButton>
        </FormComponent>
        <AvisoPoliticas>
          Conheça nossas{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://planoviver.com.br/politicas-privacidade"
          >
            políticas de privacidade.
          </a>
        </AvisoPoliticas>
        <LojasImg>
          <a href="https://play.google.com/store/apps/details?id=com.app_cliente">
            <img src={googlePlay} alt="Disponível na Google play" />
          </a>
          <a href="https://apps.apple.com/us/app/viver-app/id1495207079?l=pt&ls=1">
            <img src={appStore} alt="Disponível na Google play" />
          </a>
        </LojasImg>
      </BoxComponent>
    );
  }
}
