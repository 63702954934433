/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import logo from '../../../imgs/logo-white.png';

import {
  Cartao,
  ContainerLogo,
  Logo,
  TagCartao,
  FaixaCartao,
  CpfCliente,
  NomeCliente,
  NomesCartao,
} from './styles';

export default class CartaoViver extends Component {
  formataCpf = () => {
    const { cpf } = this.props;

    const cpfSemPontos = cpf
      .split('.')
      .join('')
      .split('-')
      .join('')
      .toString();

    const cpfFormatado = `${cpfSemPontos.substr(0, 4)} ${cpfSemPontos.substr(
      4,
      4
    )} ${cpfSemPontos.substr(8, 3)}`;

    return cpfFormatado;
  };

  render() {
    const { tag, nomeCliente } = this.props;
    const cpf = this.formataCpf();

    return (
      <Cartao>
        <ContainerLogo>
          <Logo src={logo} alt="Logo do Viver" />
          <TagCartao>{tag}</TagCartao>
        </ContainerLogo>
        <FaixaCartao>
          <CpfCliente>{cpf}</CpfCliente>
        </FaixaCartao>
        <NomesCartao>
          <NomeCliente>{nomeCliente}</NomeCliente>
        </NomesCartao>
      </Cartao>
    );
  }
}
