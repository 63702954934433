import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  img {
    margin-bottom: 20px;
  }
`;
export const Title = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;
export const BoxComponent = styled.div`
  background: #fff;
  border-radius: 20px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 50px;
  text-align: center;
  img {
    max-width: 250px;
    width: 100%;
  }
  @media (max-width: 900px) {
    padding: 30px;
  }
`;
