import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { BgLoader, MsgErrorCard } from './styles';
import * as animationData from '../json/4970-unapproved-cross.json';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const icon = () => (
  <Lottie
    options={defaultOptions}
    width={270}
    style={{
      width: 'calc(60% - 20px)',
      marginLeft: 'calc(20% + 20px)',
      marginBottom: '10px',
      overflow: 'unset',
    }}
  />
);

export default function MsgError(props) {
  return (
    <BgLoader>
      <MsgErrorCard>
        {icon()}

        <h2>Ops! Parece que algo deu errado</h2>
        <p>{props.msgDesc}</p>
        <button onClick={() => props.setOk('')} type="button">
          Tente novamente
        </button>
      </MsgErrorCard>
    </BgLoader>
  );
}
