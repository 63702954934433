/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import {
  FaMedal,
  FaUserFriends,
  FaCalendarAlt,
  FaCreditCard,
  FaHandHoldingUsd,
  FaBuilding,
  FaUser,
  FaAddressCard,
  FaBirthdayCake,
  FaHandHoldingHeart,
} from 'react-icons/fa';

import {
  Card,
  TitleCard,
  ContentCard,
  SecondTitle,
  SubtitleCard,
  DescriptionCard,
  QuebraLinha,
  BotaoCardVermelho,
  Inline,
} from './styles';

export default class Apolice extends Component {
  ajustarData = data => {
    const dataVencimento = data.split('-');
    return `${dataVencimento[2]}/${dataVencimento[1]}/${dataVencimento[0]}`;
  };

  formataFormaPagamento = formaPagamento => {
    switch (formaPagamento) {
      case 'carne-impresso':
        return 'Carnê';
      case 'cartao-credito':
        return 'Cartão de crédito (assinatura)';
      case 'assinatura-empresarial':
        return 'Assinatura Empresarial';
      default:
        return 'Nenhuma forma de pagamento configurada';
    }
  };

  render() {
    const {
      apolice,
      infosSegurado,
      dependentes,
      nivelEstipulante,
    } = this.props;
    return (
      <>

      {nivelEstipulante == 1 && (
        <BotaoCardVermelho href="/faturas">
          <FaHandHoldingUsd /> VER BOLETOS / 2ª VIA
        </BotaoCardVermelho>
      )}

      <Card>

        <TitleCard>
          <FaMedal /> {apolice.nome_produto}
        </TitleCard>

        <ContentCard>
          
          <Inline>
            {infosSegurado.id_estipulante != 1 && (
              <div>
                <SubtitleCard>
                  <FaBuilding /> Empresa
                </SubtitleCard>
                <DescriptionCard>
                  {infosSegurado.nome_estipulante}
                </DescriptionCard>
              </div>
            )}
            <div>
              <SubtitleCard>
                <FaUser /> Titular
              </SubtitleCard>
              <DescriptionCard>{infosSegurado.nome}</DescriptionCard>
            </div>
          </Inline>

          <QuebraLinha />

          <Inline>
            <div>
              <SubtitleCard>
                <FaAddressCard /> CPF
              </SubtitleCard>
              <DescriptionCard>{infosSegurado.cpf}</DescriptionCard>
            </div>
            <div>
              <SubtitleCard>
                <FaBirthdayCake /> Data de Nascimento
              </SubtitleCard>
              <DescriptionCard>
                {this.ajustarData(infosSegurado.data_nascimento)}
              </DescriptionCard>
            </div>
            <div>
              <SubtitleCard>
                <FaHandHoldingHeart /> Estado Civil
              </SubtitleCard>
              <DescriptionCard>{infosSegurado.estado_civil}</DescriptionCard>
            </div>
          </Inline>

          {dependentes.length > 0 && (
            <div>
              <QuebraLinha />

              <SecondTitle>
                <FaUserFriends /> Dependentes:
              </SecondTitle>
            </div>
          )}
          <Inline>
            {dependentes.map((dependente, index) => {
              return (
                <div key={index.toString()}>
                  <SubtitleCard>
                    <FaUser /> {dependente.nome}
                  </SubtitleCard>
                  <DescriptionCard>
                    {this.ajustarData(dependente.data_nascimento)}
                  </DescriptionCard>
                </div>
              );
            })}
          </Inline>

          <QuebraLinha />

          <SecondTitle>
            <FaCreditCard /> Detalhes do pagamento:
          </SecondTitle>
          <Inline>
            <div>
              <SubtitleCard>
                <FaCalendarAlt /> Início Vigência:
              </SubtitleCard>
              <DescriptionCard>
                {this.ajustarData(apolice.inicio_vigencia)}
              </DescriptionCard>
            </div>
            <div>
              <SubtitleCard>
                <FaCreditCard /> Forma de Pagamento:
              </SubtitleCard>
              <DescriptionCard>
                {this.formataFormaPagamento(apolice.forma_pagamento)}
              </DescriptionCard>
            </div>
          </Inline>

        </ContentCard>
      </Card>
      </>
    );
  }
}
