/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import {
  FaFileInvoiceDollar,
  FaComments,
  FaSignOutAlt,
  FaHome,
  FaIdCard,
} from 'react-icons/fa';
import { MenuBar, IconMenu } from './styles';

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: true,
    };
  }

  sair = async () => {
    sessionStorage.removeItem('idSegurado');
    sessionStorage.removeItem('idApolice');
    sessionStorage.removeItem('nivelEstipulante');
    this.setState({ login: false });
  };

  render() {
    const { login } = this.state;
    const { home, cartao, plano, saude24h, ajudar } = this.props;

    return (
      <MenuBar>
        {!login ? <Redirect to="/login" /> : ''}

        <Link to="/" className={home ? 'active' : ''}>
          <IconMenu>
            <FaHome />
          </IconMenu>
          Home
        </Link>
        <Link to="/cartao-viver" className={cartao ? 'active' : ''}>
          <IconMenu>
            <FaIdCard />
          </IconMenu>
          Cartão
        </Link>
        <Link to="/meu-plano" className={plano ? 'active' : ''}>
          <IconMenu>
            <FaFileInvoiceDollar />
          </IconMenu>
          Plano/Boleto
        </Link>
        <Link to="/saude-24h" className={saude24h ? 'active' : ''}>
          <IconMenu>24H</IconMenu>
          Saúde
        </Link>
        <Link to="/posso-ajudar" className={ajudar ? 'active' : ''}>
          <IconMenu>
            <FaComments />
          </IconMenu>
          Ajuda
        </Link>
        <a onClick={this.sair}>
          <IconMenu>
            <FaSignOutAlt />
          </IconMenu>
          Sair
        </a>
      </MenuBar>
    );
  }
}
