import React from 'react';
import { withRouter } from 'react-router-dom';
import Lottie from 'react-lottie';
import { BgLoader, MsgSuccessCard } from './styles';
import * as animationData from '../json/checked_user.json';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const icon = () => (
  <Lottie
    options={defaultOptions}
    height={270}
    width={400}
    style={{
      width: 'calc(100% - 20px)',
      marginLeft: '20px',
      marginBottom: '10px',
    }}
  />
);

function MsgSuccess(props) {
  return (
    <BgLoader>
      <MsgSuccessCard>
        {icon()}

        <h2>{props.msgTitle}</h2>
        <button onClick={() => props.history.push(props.msgLink)} type="button">
          {props.msgDescLink}
        </button>
      </MsgSuccessCard>
    </BgLoader>
  );
}

export default withRouter(MsgSuccess);
