/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { FaIdCard } from 'react-icons/fa';
import { BoxComponent, Container, Title, BoxCartoes } from './styles';
import Menu from '../../components/Menu';
import logo from '../../imgs/logo-white.png';
import ApiErp from '../../services/ApiErp';
import CartaoViverView from '../../views/cards/CartaoViver';
import Loader from '../../components/Loader';

import checkTokenByStatusHTTP from '../../services/checkTokenByStatusHTTP';

export default class CartaoViver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dependentes: [],
      infosSegurado: null,
      loading: true,
    };
    this.main();
  }

  buscarDependentes = async idSegurado => {
    const response = await ApiErp.get(`segurado/dependentes/${idSegurado}`);
    checkTokenByStatusHTTP().valid(response.status);
    if (response.ok) {
      const { ok, output } = response.data;
      if (ok) {
        return output;
      }
    }
    return [];
  };

  buscarInfosSegurado = async idSegurado => {
    const response = await ApiErp.get(`segurado/${idSegurado}`);

    checkTokenByStatusHTTP().valid(response.status);

    if (response.ok) {
      const { ok, output } = response.data;
      if (ok) {
        return output;
      }
    }
    return null;
  };

  main = async () => {
    const idSegurado = await sessionStorage.getItem('idSegurado');
    const token = await sessionStorage.getItem('token');
    await ApiErp.setHeader('Authorization', `Bearer ${token}`);

    const infosSegurado = await this.buscarInfosSegurado(idSegurado);
    const dependentes = await this.buscarDependentes(idSegurado);

    this.setState({
      loading: false,
      dependentes,
      infosSegurado,
    });
  };

  render() {
    const { infosSegurado, dependentes, loading } = this.state;
    return (
      <Container>
        <Loader active={loading} />
        <img src={logo} alt="Logo do Viver" />
        <Menu cartao />
        <BoxComponent>
          <Title>
            <FaIdCard /> Cartões Digitais
          </Title>
          <BoxCartoes>
            {infosSegurado != null && (
              <CartaoViverView
                nomeCliente={infosSegurado.nome}
                cpf={infosSegurado.cpf}
                tag="TITULAR"
              />
            )}

            {dependentes.map((dependente, index) => {
              let tag;
              if (dependente.tipo == 1) {
                tag = 'DEP';
              } else {
                tag = 'TITULAR 2';
              }
              return (
                <CartaoViverView
                  key={index.toString()}
                  nomeCliente={dependente.nome}
                  cpf={dependente.cpf}
                  tag={tag}
                />
              );
            })}
          </BoxCartoes>
        </BoxComponent>
      </Container>
    );
  }
}
