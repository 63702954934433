/* eslint-disable no-restricted-properties */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import {
  FaHandHoldingUsd,
  FaInfoCircle,
  FaRegCalendarAlt,
  FaFileInvoiceDollar,
} from 'react-icons/fa';

import {
  Card,
  TitleCard,
  SubtitleCard,
  DescriptionCard,
  ContainerBotoesCard,
  BotaoCardVerde,
} from './styles';
import { Inline } from '../Apolice/styles';

export default class Fatura extends Component {
  ajustarData = data => {
    const dataVencimento = data.split('-');
    return `${dataVencimento[2]}/${dataVencimento[1]}/${dataVencimento[0]}`;
  };

  arredondar = str => {
    if (str.indexOf(',') !== -1) str = str.replace(',', '.');

    const casas = Math.pow(10, 2);
    str = parseFloat(str) * casas;
    const strAround = Math.floor(str) / casas;
    const valor = strAround.toString().split('.');

    if (!valor[1]) valor[1] = '00';
    if (valor[1].length === 1) valor[1] += '0';

    return `${valor[0]},${valor[1]}`;
  };

  render() {
    const { fatura } = this.props;
    return (
      <Card>
        {fatura.status === 'Pendente' && (
          <ContainerBotoesCard>
            <BotaoCardVerde href={fatura.link_arquivo} target="_blank">
              <FaHandHoldingUsd /> Pagar boleto
            </BotaoCardVerde>
          </ContainerBotoesCard>
        )}
        <TitleCard>
          <FaInfoCircle /> Fatura está ({fatura.status == 'Reprogramada' ? 'Pendente' : fatura.status})
        </TitleCard>
        <Inline>
          <div>
            <SubtitleCard>
              <FaRegCalendarAlt /> Vencimento:
            </SubtitleCard>
            <DescriptionCard>
              {this.ajustarData(fatura.data_vencimento)}
            </DescriptionCard>
          </div>
          <div>
            <SubtitleCard>
              <FaFileInvoiceDollar /> Valor:
            </SubtitleCard>
            <DescriptionCard>
              R$ {this.arredondar(fatura.valor)}
            </DescriptionCard>
          </div>
        </Inline>
      </Card>
    );
  }
}
