import styled from 'styled-components';

export const ImageComponent = styled.div`
  margin: 25px auto;
  text-align: center;
`;
export const BoxComponent = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 550px;
  width: 100%;
  margin: 50px auto;
  padding: 50px;
  text-align: center;

  h1 {
    text-align: left;
    padding-bottom: 20px;
  }
  h4 {
    text-align: left;
    padding-bottom: 20px;
  }
  img {
    max-width: 300px;
    width: 100%;
    margin-bottom: 40px;
  }
  @media (max-width: 480px) {
    padding: 20px;s
  }
  @media (max-width: 900px) {
    width: 90%;
    left: 5%;
    margin-top: 50px;
  }
`;
