/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
import React from 'react';
import {
  FaComments,
  FaPhone,
  FaMapMarkerAlt,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaEnvelope,
} from 'react-icons/fa';
import {
  BoxComponent,
  Container,
  Title,
  Paragrafo,
  Inline,
  ButtonWhite,
  ButtonGreen,
} from './styles';
import Menu from '../../components/Menu';
import logo from '../../imgs/logo-white.png';

export default function PossoAjudar() {
  return (
    <Container>
      <img src={logo} alt="Logo do Viver" />
      <Menu ajudar />
      <BoxComponent>
        <Title>
          <FaComments /> Estamos aqui para você
        </Title>

        <Paragrafo>
          Está com alguma dúvida? Entre em contato conosco que iremos te ajudar.
        </Paragrafo>

        <Inline>
          <ButtonWhite href="tel:(47)3033-8008" target="_blank">
            <FaPhone /> Ligue para nós
          </ButtonWhite>
          <ButtonWhite
            href="https://goo.gl/maps/i767wwDdeGBoAr4Z7"
            target="_blank"
          >
            <FaMapMarkerAlt /> Venha conversar
          </ButtonWhite>
        </Inline>

        <Inline>
          <ButtonGreen
            href="https://www.facebook.com/PLANOVIVER/"
            target="_blank"
          >
            <FaFacebook />
          </ButtonGreen>
          <ButtonGreen
            href="https://www.instagram.com/planoviver/"
            target="_blank"
          >
            <FaInstagram />
          </ButtonGreen>
          <ButtonGreen
            href="https://api.whatsapp.com/send?phone=5547991575716&text="
            target="_blank"
          >
            <FaWhatsapp />
          </ButtonGreen>
          <ButtonGreen href="mailto:contato@planoviver.com" target="_blank">
            <FaEnvelope />
          </ButtonGreen>
        </Inline>
      </BoxComponent>
    </Container>
  );
}
