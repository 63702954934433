/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { LoaderCard, BgLoader, TextLoader } from './styles';

export default class Loader extends Component {
  render() {
    const { active } = this.props;
    if (active) {
      return (
        <BgLoader>
          <LoaderCard>
            <FaCircleNotch />
            <TextLoader>Carregando</TextLoader>
          </LoaderCard>
        </BgLoader>
      );
    }
    return null;
  }
}
