import styled from 'styled-components';

export const BgLoader = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
export const SubmitButton = styled.button.attrs({ type: 'submit' })`
  width: 100%;
  padding: 18px 15px;
  margin-top: 60px;
  border-radius: 5px;
  color: #fff;
  font-size: 17px;
  border: 1px solid #fff;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: -webkit-linear-gradient(to left top, #2d8583, #1e5d55);
  background: -moz-linear-gradient(to left top, #2d8583, #1e5d55);
  background: -o-linear-gradient(to left top, #2d8583, #1e5d55);
  background: linear-gradient(to left top, #2d8583, #1e5d55);

  div {
    text-align: center;
  }
  span {
    color: #fff !important;
    margin-left: 130px;
  }
  svg {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition: 0.5s ease-out;
  }
  &:hover > svg {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    transition: 0.5s ease-in;
  }
  &:disabled {
    background: #ddd;
    cursor: not-allowed;
  }
  @media (max-width: 470px) {
    span {
      margin-left: 0px;
      margin: 0 auto;
    }
  }
`;

export const FormComponent = styled.form`
  
  select {
    height: 50px;
    background-color: transparent;
    border-radius: 5px;
    padding-left: 10px;
    color: #797979;
  }
  .day {
    width: 15%;
    margin-right: 5px;
  }
  .month {
    width: 62%;
    margin-right: 5px;
  }
  .year {
    width: 20%;
  }
  option {
    color: #5d5d5d;
    font-size: 17px;
    opacity: 0.7;
  }
  input {
    width: 100%;
    padding: 20px 30px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #a5a5a5;
    font-size: 13px;
  }
  input:focus {
    border: 1px solid #0a7a73;
    transition: 0.5s ease-in;
    -webkit-transition: 0.5s ease-in;
  }
  input::placeholder {
    color: #5d5d5d;
    font-size: 17px;
    opacity: 0.7;
  }
  .input-checkbox {
    width: 30px;
    margin-right: 10px;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  .terms-area {
    padding-top: 20px;
  }
  span {
    color: #696969;
    font
  }
  a {
    text-decoration: none;
    color: #0a7a73;
  }
  @media (max-width: 480px) {
    .month {
      width: calc(100% - 45%);
      padding-left: 0px;
      margin-right: 2px;
    }
    .day {
      width: 20%;
      margin-right: 2px;
    }
    .year {
      padding-left: 2px;
    }
  }
`;

export const LabelForm = styled.div`
  margin-top: 25px;
  margin-bottom: 18px;
  text-align: left;

  color: #0a7a73;
  font-weight: 600;

`;
